import { uxCatLevels } from '@local-types/uxcat-types/types';

export const findLevelDetail = (
  userLevel: number,
  allLevels: any[],
): any | undefined => {
  return allLevels?.find(detail => {
    if (detail && detail.attributes) {
      return detail.attributes.level === userLevel;
    }
  });
};

export function normalizeUserData(data) {
  if (data && data.user) {
    return data.user;
  }
  return data;
}

export const isLevelMilestone = (level, mileStoneLevel) => {
  const milestoneLevels = [11, 12, 13, 14, 15];

  if (!milestoneLevels.includes(level)) {
    return false;
  }

  const levelIndex = milestoneLevels.indexOf(level);
  const m2Index = milestoneLevels.indexOf(mileStoneLevel);

  return levelIndex >= m2Index;
};

export const formatDate = isoString => {
  const date = new Date(isoString);
  if (date.toString() === 'Invalid Date') {
    return undefined;
  }
  return date.toLocaleDateString('en-GB').replace(/\//g, '.');
};

export const cleanTitle = (title: string): string => {
  return title.replace(/#\d+\s*/, '');
};

export const getPointsToNextLevel = (
  uxCatLevels: uxCatLevels[],
  currentLevel: number,
  currentPoints: number,
  level: number,
): string => {
  const nextLevelPoints = uxCatLevels?.[level + 1];
  if (currentLevel === level) {
    return `${currentPoints}/${nextLevelPoints || 0}`;
  }
  return `0/${nextLevelPoints || 0}`;
};

export const getNotifiedAchievements = (achievementList, notificationsData) =>
  !!achievementList &&
  achievementList
    ?.filter(achievement => {
      return (
        !!notificationsData &&
        notificationsData?.some(
          notification => notification.achievementName === achievement?.slug,
        )
      );
    })
    ?.map(achievement => {
      const matchingNotificationId = notificationsData?.find(
        notification => notification.achievementName === achievement?.slug,
      )?.id;
      return { newId: matchingNotificationId, ...achievement };
    });

export const getBiasNumber = (input: string): string | null => {
  const match = input.match(/#(\d+)/);
  return match ? match[1] : null;
};

export const sortAchievements = achievements => {
  return achievements.sort((a, b) => {
    // If 'a' has 'unlockedAt' and 'b' doesn't, 'a' comes first
    if (a?.unlockedAt && !b?.unlockedAt) return -1;
    // If 'b' has 'unlockedAt' and 'a' doesn't, 'b' comes first
    if (!a?.unlockedAt && b?.unlockedAt) return 1;
    // If both or neither have 'unlockedAt', maintain their order
    return 0;
  });
};

export const findAchievementListByType = (achievementsList, type: string) => {
  const list = achievementsList.filter(
    achievement => achievement.collection_type === type,
  );
  return list;
};
export const findAchievementListBySubType = (
  achievementsList,
  type: string,
) => {
  const list = achievementsList.filter(
    achievement => achievement.type === type,
  );
  return list;
};

export const enhanceAchievementsWithGroups = (
  achievements,
  userAchievements,
) => {
  const groupedAchievements = achievements.reduce((acc, achievement) => {
    const match = achievement.slug.match(/(_BRONZE|_SILVER|_GOLD)/);
    if (match) {
      const baseName = achievement.slug.replace(/(_BRONZE|_SILVER|_GOLD)/, '');

      if (!acc[baseName])
        acc[baseName] = { BRONZE: null, SILVER: null, GOLD: null };

      if (achievement.slug.includes('BRONZE'))
        acc[baseName].BRONZE = achievement;
      if (achievement.slug.includes('SILVER'))
        acc[baseName].SILVER = achievement;
      if (achievement.slug.includes('GOLD')) acc[baseName].GOLD = achievement;
    } else {
      acc[achievement.slug] = achievement;
    }

    return acc;
  }, {});

  return Object.values(groupedAchievements).map(achievementGroup => {
    if (
      // @ts-ignore
      achievementGroup.BRONZE ||
      // @ts-ignore
      achievementGroup.SILVER ||
      // @ts-ignore
      achievementGroup.GOLD
    ) {
      // @ts-ignore
      const { BRONZE, SILVER, GOLD } = achievementGroup;
      const userAchievement = userAchievements?.find(
        userAch =>
          userAch.name === GOLD?.slug ||
          userAch.name === SILVER?.slug ||
          userAch.name === BRONZE?.slug,
      );

      if (userAchievement) {
        const unlockedSlug = userAchievement.name;
        if (unlockedSlug.includes('GOLD') && GOLD) {
          return {
            ...GOLD,
            unlockedAt: formatDate(userAchievement.unlockedAt),
          };
        } else if (unlockedSlug.includes('SILVER') && SILVER) {
          return {
            ...SILVER,
            unlockedAt: formatDate(userAchievement.unlockedAt),
          };
        } else if (unlockedSlug.includes('BRONZE') && BRONZE) {
          return {
            ...BRONZE,
            unlockedAt: formatDate(userAchievement.unlockedAt),
          };
        }
      }

      return BRONZE || SILVER || GOLD;
    } else {
      const userAchievement = userAchievements?.find(
        // @ts-ignore
        userAch => userAch.name === achievementGroup.slug,
      );
      return userAchievement
        ? {
            // @ts-ignore
            ...achievementGroup,
            unlockedAt: formatDate(userAchievement.unlockedAt),
          }
        : achievementGroup;
    }
  });
};
